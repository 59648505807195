import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo.svg'
import _imports_1 from '@/assets/espana.png'
import _imports_2 from '@/assets/reino-unido.png'
import _imports_3 from '@/assets/francia.png'


const _hoisted_1 = {
  "data-test-id": "drawer",
  class: "vtmn-border vtmn-fixed vtmn-z-50 vtmn-w-72 vtmn-right-3 vtmn-top-22 vtmn-bg-white shadow"
}
const _hoisted_2 = { class: "vtmn-m-2 vtmn-bg-white" }
const _hoisted_3 = { class: "vtmn-flex vtmn-justify-start vtmn-mt-6 vtmn-gap-4" }
const _hoisted_4 = { "data-test-id": "userInfo" }
const _hoisted_5 = { class: "vtmn-font-bold vtmn-uppercase" }
const _hoisted_6 = { class: "vtmn-flex vtmn-justify-center vtmn-space-x-4 vtmn-mt-3 hover:vtmn-text-brand-digital" }
const _hoisted_7 = { class: "vtmn-uppercase" }
const _hoisted_8 = {
  "data-test-id": "languages",
  class: "vtmn-grid vtmn-grid-cols-3"
}
const _hoisted_9 = { class: "vtmn-mt-6" }

import { LanguageTypes } from '@/modules/oauth/interfaces/ResponseUserInfo';
import { onMounted } from 'vue';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DrawerComp',
  props: {
    userInfo: {}
  },
  emits: ["changeLanguage", "logout"],
  setup(__props: any) {

const offeringLink = ref<HTMLElement>();
onMounted(() => {
    (offeringLink.value as HTMLImageElement).setAttribute('href',"https://support.decathlon.net/saw/ess/offeringPage/107014470");
})





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "h-6 deca-header" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[9] || (_cache[9] = _createElementVNode("div", {
        class: "vtmn-flex vtmn-justify-center",
        "data-test-id": "logoDrower"
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "logo app"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("div", null, [
          _createElementVNode("span", { class: "vtmx-user-line vtmn-text-5xl vtmn-text-white vtmn-rounded-full vtmn-bg-brand-digital vtmn-p-2" })
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.userInfo.name), 1),
          _createElementVNode("small", null, _toDisplayString(_ctx.userInfo.email), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.userInfo.job), 1)
        ])
      ]),
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "vtmn-border-b-2 vtmn-border-brand-digital vtmn-my-4 vtmn-m-1" }, null, -1)),
      _createElementVNode("div", _hoisted_6, [
        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "vtmx-customer-service-line vtmn-text-2xl" }, null, -1)),
        _createElementVNode("a", {
          ref_key: "offeringLink",
          ref: offeringLink,
          target: "_blank",
          class: ""
        }, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('ask_support')), 1)
        ], 512)
      ]),
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "vtmn-border-b-2 vtmn-border-brand-digital vtmn-my-4 vtmn-m-1" }, null, -1)),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          "data-test-id": "changeLanguageEs",
          class: "vtmn-flex vtmn-justify-center",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('changeLanguage', 'ES')))
        }, _cache[6] || (_cache[6] = [
          _createElementVNode("div", { class: "hover:vtmn-text-brand-digital vtmn-cursor-pointer" }, [
            _createElementVNode("img", {
              src: _imports_1,
              width: "55",
              alt: "spanish",
              class: "hover:vtmn-shadow-xl vtmn-rounded-full"
            }),
            _createElementVNode("p", null, "Español")
          ], -1)
        ])),
        _createElementVNode("div", {
          "data-test-id": "changeLanguageEn",
          class: "vtmn-flex vtmn-justify-center",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('changeLanguage', 'EN')))
        }, _cache[7] || (_cache[7] = [
          _createElementVNode("div", { class: "hover:vtmn-text-brand-digital vtmn-cursor-pointer" }, [
            _createElementVNode("img", {
              src: _imports_2,
              width: "55",
              alt: "english",
              class: "hover:vtmn-shadow-xl vtmn-rounded-full"
            }),
            _createElementVNode("p", null, "English")
          ], -1)
        ])),
        _createElementVNode("div", {
          "data-test-id": "changeLanguageFr",
          class: "vtmn-flex vtmn-justify-center",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('changeLanguage', 'FR')))
        }, _cache[8] || (_cache[8] = [
          _createElementVNode("div", { class: "hover:vtmn-text-brand-digital vtmn-cursor-pointer" }, [
            _createElementVNode("img", {
              src: _imports_3,
              width: "55",
              alt: "français",
              class: "hover:vtmn-shadow-x vtmn-rounded-full"
            }),
            _createElementVNode("p", null, "Français")
          ], -1)
        ]))
      ]),
      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "vtmn-border-b-2 vtmn-border-brand-digital vtmn-mt-4 vtmn-m-3" }, null, -1)),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('logout'))),
          class: "vtmn-w-full vtmn-text-brand-digital vtmn-border vtmn-py-4 hover:vtmn-bg-brand-digital hover:vtmn-text-white"
        }, "LOGOUT")
      ])
    ])
  ]))
}
}

})