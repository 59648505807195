import { AxiosResponse } from "axios";
import { IAuthService } from "../interfaces/AuthService";
import { IRefreshToken } from "../interfaces/IRefreshToken";
import { IToken } from "../interfaces/IToken";
import hdaRest from "@/modules/hdaModule/rest/hdaRest";
import authRest from "../api/authRest";

export class AuthService implements IAuthService {
  private path = "/v1/authorization";

  getToken(code: string): Promise<AxiosResponse<IToken>> {
    return authRest.post<IToken>(`${this.path}/token`, { code });
  }
  refreshToken(refreshToken: string): Promise<AxiosResponse<IRefreshToken>> {
    return authRest.post<IRefreshToken>(`${this.path}/refresh`, { refreshToken });
  }
  revokeSession(piSri: string): Promise<AxiosResponse<{ id: string }>> {
    return hdaRest.post<{ id: string }>(`${this.path}/revoke`, { piSri });
  }
}
