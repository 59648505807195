import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import NavbarComp from './components/molecules/NavbarComp.vue';
  import fedidService from './modules/oauth/services/FedidService';
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

  fedidService.getTokenAndSaveData();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(NavbarComp),
    _createVNode(_component_router_view)
  ], 64))
}
}

})