import axios from "axios";
import { ToastMixin } from "@/tools/ToastMixin";
import applyCaseMiddleware from "axios-case-converter";
import checkErrorsApiResponse from "@/tools/checkErrorsApiResponse";
import useAuth from "../composables/useAuth";

const toast = ToastMixin("error");

const authRest = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.VUE_APP_API_KEY,
    },
  })
);

authRest.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 400) {
      window.location.href = window.location.origin;
    }
    if (error.response.status === 406) {
      const { revokeSession } = useAuth();
      revokeSession();
      window.location.reload();
    }

    const errorData = checkErrorsApiResponse(error);

    if (errorData.icon != "success") {
      if (!errorData.text) {
        await toast.fire({
          icon: errorData.icon,
          title: errorData.title,
        });
      } else {
        await toast.fire({
          icon: errorData.icon,
          title: errorData.title,
          text: errorData.text,
        });
      }
    }
    return Promise.reject(error);
  }
);

export default authRest;
