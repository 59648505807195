import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/logo.svg'
import _imports_1 from '@/assets/decathlon-logo-with-outline.svg'


const _hoisted_1 = {
  id: "default-navbar",
  "aria-label": "default navbar",
  class: "vtmn-p-2 vtmn-border-b vtmn-border-gray-200"
}
const _hoisted_2 = { class: "md:vtmn-flex md:vtmn-justify-between" }
const _hoisted_3 = {
  key: 0,
  class: "vtmn-flex vtmn-justify-end vtmn-gap-3 vtmn-pr-4"
}
const _hoisted_4 = { class: "vtmn-text-center vtmn-h-16 vtmn-w-24 hover:vtmn-bg-blue-50 vtmn-rounded vtmn-p-2" }
const _hoisted_5 = { class: "vtmn-h-1/3" }
const _hoisted_6 = { class: "vtmn-capitalize" }
const _hoisted_7 = { class: "vtmn-text-center vtmn-h-16 vtmn-w-24 hover:vtmn-bg-blue-50 vtmn-rounded vtmn-p-2" }
const _hoisted_8 = { class: "vtmn-h-1/3" }
const _hoisted_9 = { class: "vtmn-capitalize" }
const _hoisted_10 = { class: "vtmn-text-center vtmn-h-16 vtmn-w-24 hover:vtmn-bg-blue-50 vtmn-rounded vtmn-p-2" }
const _hoisted_11 = { class: "vtmn-h-1/3" }
const _hoisted_12 = { class: "vtmn-capitalize" }
const _hoisted_13 = { class: "vtmn-text-center vtmn-h-16 vtmn-w-24 hover:vtmn-bg-blue-50 vtmn-rounded vtmn-p-2" }
const _hoisted_14 = { class: "vtmn-h-1/3" }
const _hoisted_15 = { class: "vtmn-capitalize" }
const _hoisted_16 = { class: "vtmn-text-center vtmn-h-16 vtmn-w-24 hover:vtmn-bg-blue-50 vtmn-rounded vtmn-p-2" }
const _hoisted_17 = { class: "vtmn-h-1/3" }
const _hoisted_18 = { class: "vtmn-capitalize" }
const _hoisted_19 = { class: "vtmn-h-1/3" }
const _hoisted_20 = { class: "vtmn-capitalize" }
const _hoisted_21 = {
  id: "secondary-navbar",
  "aria-label": "small screen navbar",
  class: "vtmn-p-2 vtmn-border-b vtmn-border-gray-200"
}
const _hoisted_22 = {
  key: 0,
  class: "vtmn-absolute vtmn-left-3 vtmn-bg-white vtmn-z-50 vtmn-w-80 md:vtmn-w-1/3 vtmn-shadow-lg vtmn-bg-gray-100 vtmn-border vtmn-border-gray-50 vtmn-rounded-md"
}
const _hoisted_23 = { class: "vtmn-border-b vtmn-border-gray-300 hover:vtmn-bg-gray-100" }
const _hoisted_24 = { class: "vtmn-pt-1" }
const _hoisted_25 = { class: "vtmn-uppercase" }
const _hoisted_26 = { class: "vtmn-border-b vtmn-border-gray-300 hover:vtmn-bg-gray-100" }
const _hoisted_27 = { class: "vtmn-pt-1" }
const _hoisted_28 = { class: "vtmn-uppercase" }
const _hoisted_29 = { class: "vtmn-border-b vtmn-border-gray-300 hover:vtmn-bg-gray-100" }
const _hoisted_30 = { class: "vtmn-pt-1" }
const _hoisted_31 = { class: "vtmn-uppercase" }
const _hoisted_32 = { class: "vtmn-border-b vtmn-border-gray-300 hover:vtmn-bg-gray-100" }
const _hoisted_33 = { class: "vtmn-pt-1" }
const _hoisted_34 = { class: "vtmn-uppercase" }

import { ref, watch } from "vue";
import DrawerComp from "../molecules/DrawerComp.vue";
import useLanguage from "@/modules/language/composables/useLanguage";
import { LanguageTypes } from "../../modules/oauth/interfaces/ResponseUserInfo";
import useAuth from "@/modules/oauth/composables/useAuth";
import fedidService from "../../modules/oauth/services/FedidService";


export default /*@__PURE__*/_defineComponent({
  __name: 'NavbarComp',
  setup(__props) {

const showDrawer = ref(false);
const user = ref({
  name: "",
  email: "",
  job: "",
});
const roles = ref<string[]>();
const secondNavbar = ref(false);

const { changeLanguage } = useLanguage();
const { userInfo, getRolesByAppName } = useAuth();

const onShowDrawer = () => (showDrawer.value = !showDrawer.value);
const onChangeLanguage = (language: LanguageTypes) => {
  changeLanguage(language);
};
const showHideSecondNavbar = () => (secondNavbar.value = !secondNavbar.value);
const hideSecondNavbar = () => (secondNavbar.value = false);

const onLogout = () => {
  fedidService.logout();
};

if (userInfo.value) {
  user.value.email = userInfo.value?.mail;
  user.value.job = userInfo.value?.jobname;
  user.value.name = userInfo.value.displayName;
  roles.value = getRolesByAppName("HDA")?.map((role) => role.role);
}

watch(userInfo, () => {
  if (userInfo.value) {
    user.value.email = userInfo.value?.mail;
    user.value.job = userInfo.value?.jobname;
    user.value.name = userInfo.value.displayName;
    roles.value = getRolesByAppName("HDA")?.map((role) => role.role);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[7] || (_cache[7] = _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "logo decathlon"
          })
        ], -1)),
        (roles.value?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_router_link, { to: { name: 'collaborator' } }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "vtmn-h-2/3" }, [
                      _createElementVNode("span", { class: "vtmx-home-line vtmn-text-3xl" })
                    ], -1)),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("hda")), 1)
                    ])
                  ])
                ]),
                _: 1
              }),
              (roles.value?.includes('WAREHOUSEMANAGER') || roles.value?.includes('SUPERADMIN'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: { name: 'explotation' }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "vtmn-h-2/3" }, [
                          _createElementVNode("span", { class: "vtmx-function-line vtmn-text-3xl" })
                        ], -1)),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("explotation")), 1)
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (roles.value?.includes('ECONOMIC') || roles.value?.includes('SUPERADMIN'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    to: { name: 'economic' }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_10, [
                        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "vtmn-h-2/3" }, [
                          _createElementVNode("span", { class: "vtmx-money-euro-box-line vtmn-text-3xl" })
                        ], -1)),
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("economic")), 1)
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (roles.value?.includes('WAREHOUSEMANAGER') || roles.value?.includes('SUPERADMIN'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 2,
                    to: { name: 'users' }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_13, [
                        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "vtmn-h-2/3" }, [
                          _createElementVNode("span", { class: "vtmx-walk-line vtmn-text-3xl" })
                        ], -1)),
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("users")), 1)
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (roles.value?.includes('SUPERADMIN'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 3,
                    to: { name: 'configuration' }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_16, [
                        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "vtmn-h-2/3" }, [
                          _createElementVNode("span", { class: "vtmx-settings-line vtmn-text-3xl" })
                        ], -1)),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t("configuration")), 1)
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                "data-test-id": "showDrawer",
                onClick: onShowDrawer,
                class: "vtmn-cursor-pointer vtmn-text-center vtmn-h-16 vtmn-w-24 hover:vtmn-bg-blue-50 vtmn-rounded vtmn-p-2"
              }, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "vtmn-h-2/3" }, [
                  _createElementVNode("span", { class: "vtmx-user-line vtmn-text-3xl" })
                ], -1)),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t("account")), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("nav", _hoisted_21, [
      _createElementVNode("div", { class: "vtmn-flex vtmn-justify-between vtmn-gap-2" }, [
        _createElementVNode("div", null, [
          _createElementVNode("span", {
            onClick: showHideSecondNavbar,
            class: "vtmx-menu-line vtmn-text-3xl vtmn-p-3 vtmn-cursor-pointer hover:vtmn-text-brand-digital"
          })
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: _imports_1,
            alt: "logo decathlon"
          })
        ], -1)),
        _createElementVNode("div", { class: "vtmn-flex vtmn-justify-end" }, [
          _createElementVNode("span", {
            onClick: onShowDrawer,
            class: "vtmx-user-line vtmn-text-3xl vtmn-pr-3 vtmn-cursor-pointer hover:vtmn-text-brand-digital"
          })
        ])
      ]),
      (secondNavbar.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_router_link, { to: { name: 'collaborator' } }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    onClick: hideSecondNavbar,
                    class: "vtmn-text-center vtmn-flex vtmn-h-16 vtmn-w-full hover:vtmn-bg-blue-50 vtmn-rounded vtmn-p-2 vtmn-gap-2"
                  }, [
                    _cache[9] || (_cache[9] = _createElementVNode("div", null, [
                      _createElementVNode("span", { class: "vtmx-home-line vtmn-text-3xl" })
                    ], -1)),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t("hda")), 1)
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_26, [
              (roles.value?.includes('WAREHOUSEMANAGER') || roles.value?.includes('SUPERADMIN'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: { name: 'explotation' }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        onClick: hideSecondNavbar,
                        class: "vtmn-text-center vtmn-flex vtmn-h-16 vtmn-w-full hover:vtmn-bg-blue-50 vtmn-rounded vtmn-p-2 vtmn-gap-2"
                      }, [
                        _cache[10] || (_cache[10] = _createElementVNode("div", null, [
                          _createElementVNode("span", { class: "vtmx-function-line vtmn-text-3xl" })
                        ], -1)),
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.$t("explotation")), 1)
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_29, [
              (roles.value?.includes('ECONOMIC') || roles.value?.includes('SUPERADMIN'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: { name: 'economic' }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        onClick: hideSecondNavbar,
                        class: "vtmn-text-center vtmn-flex vtmn-h-16 vtmn-w-full hover:vtmn-bg-blue-50 vtmn-rounded vtmn-p-2 vtmn-gap-2"
                      }, [
                        _cache[11] || (_cache[11] = _createElementVNode("div", null, [
                          _createElementVNode("span", { class: "vtmx-money-euro-box-line vtmn-text-3xl" })
                        ], -1)),
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.$t("economic")), 1)
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_32, [
              (roles.value?.includes('SUPERADMIN'))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: { name: 'configuration' }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        onClick: hideSecondNavbar,
                        class: "vtmn-text-center vtmn-flex vtmn-h-16 vtmn-w-full hover:vtmn-bg-blue-50 vtmn-rounded vtmn-p-2 vtmn-gap-2"
                      }, [
                        _cache[12] || (_cache[12] = _createElementVNode("div", null, [
                          _createElementVNode("span", { class: "vtmx-settings-line vtmn-text-3xl" })
                        ], -1)),
                        _createElementVNode("div", _hoisted_33, [
                          _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.$t("configuration")), 1)
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (showDrawer.value = false))
    }, [
      (showDrawer.value)
        ? (_openBlock(), _createBlock(DrawerComp, {
            key: 0,
            userInfo: user.value,
            onChangeLanguage: onChangeLanguage,
            onLogout: onLogout
          }, null, 8, ["userInfo"]))
        : _createCommentVNode("", true)
    ], 32)
  ], 64))
}
}

})